import { GetPaymentConfigResponse } from 'api/api';
import numeral from 'numeral';
import queryString from 'query-string';

import { getHost, getIdentityHost } from './env';

export const toSearchParams = (search: string) => queryString.parse(search);

export enum INSTORE_STEPS {
  Register,
  Verify,
  KycSuccess,
  KycId,
  Order,
  Payment,
}

export const fromCurrency = (amount: string): number | null =>
  amount !== 'N/A' ? Number(numeral(amount).multiply(100).format('0')) : null;

export const toCurrency = (amount: number | null, showZeroCents?: boolean): string =>
  amount !== null
    ? numeral(amount)
        .divide(100)
        .format(`$0,0.${showZeroCents ? '00' : '[00]'}`)
    : 'N/A';

export const loadScript = async (src: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = () => resolve();
    script.onerror = () => reject();
    script.src = src;
    document.head.appendChild(script);
  });

let promise: Promise<void[]> | null = null;
export const loadScripts = async (config: GetPaymentConfigResponse): Promise<void[]> => {
  if (promise) return promise;

  const host = await getHost();
  const identityHost = await getIdentityHost();

  let checkout_v3 = `https://checkout-v3.${host}/v3/checkout-v3.0.0.min.js`;
  let checkout_v2 = `https://checkout.${host}/v2/checkout-v2.1.0.min.js`;
  let identity = `https://identity.${identityHost}/dist/identity.min.js`;

  const useCheckoutV2 = config.merchantTags.includes('CheckoutV2') || config.marketplaceTags.includes('CheckoutV2');

  promise = Promise.all([loadScript(useCheckoutV2 ? checkout_v2 : checkout_v3), loadScript(identity)]);
  return promise;
};

export const hexToRGB = (hex: string) => {
  const rgb = parseInt(hex.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = rgb & 0xff;
  return `${r},${g},${b}`;
};
